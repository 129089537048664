import { useState, useEffect } from 'react';
import styled from 'styled-components';

export const ScrollToTop = () => {
  const [shown, setShown] = useState(false);
  useEffect(() => {
    const scrollCallback = () => {
      const scrolledFromTop = window.scrollY;
      setShown(() => scrolledFromTop > 300);
    };
    window.addEventListener('scroll', scrollCallback);
    scrollCallback();
    // clean-up function
    return () => {
      window.removeEventListener('scroll', scrollCallback);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0 });
  };

  return (
    <ToTopWrapper shown={shown} onClick={scrollToTop}>
      <button>↑</button>
    </ToTopWrapper>
  );
};

const ToTopWrapper = styled.div`
  display: ${({ shown }) => (shown ? 'block' : 'none')};
  position: fixed;
  bottom: 10px;
  right: 20px;
  z-index: 50;

  button {
    font-size: 25px;
    background-color: #d00c29;
    color: #fff;
    width: 45px;
    height: 45px;
    opacity: 0.75;
    border: 1px solid #e7eae8;
    border-radius: 8px;
    cursor: pointer;
    animation: bounceAnimation 1s infinite;
  }

  @keyframes bounceAnimation {
    0%, 100% {
      transform: translateY(-25%);
      animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }

    50% {
      transform: translateY(0);
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
  }

  @media (max-width: 425px) {
    button {
      font-size: 21px;
      width: 35px;
      height: 35px;
    }
  }
`;
