import React, { Suspense, lazy } from "react";
import GlobalStyles from "./assets/styles/GlobalStyle";
import {ScrollToTop} from "./Components/ScrollToTop.js"
import "./App.css";

import Loader from "./Components/Loader";

// Lazy load components
const Hero = lazy(() => import("./Components/Hero"));
const NewSections = lazy(() => import("./Components/NewSections"));
const Logistics = lazy(() => import("./Components/Logistics"));
const Footer = lazy(() => import("./Components/Footer"));

function App() {

  
  return (
    <>
      <GlobalStyles />
     <ScrollToTop/>
        <Hero />
      <Suspense fallback={<Loader />}>
        <NewSections />
      </Suspense>
      <Suspense fallback={<Loader />}>
         <Logistics />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <Footer />
      </Suspense>
    </>
  );
}

export default App;
